import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { assert } from '@/utils/error';
import {
  PlayerGameRoundInspectionV2,
  PlayerGameRoundInspectionV2Variables,
} from './__generated__/PlayerGameRoundInspectionV2';
import { queryParams } from './queryParams';

const PLAYER_GAME_ROUNDS_QUERY = gql`
  query PlayerGameRoundInspectionV2($playerId: ID!, $gameRoundId: String!) {
    player(playerId: $playerId) {
      id
      gameRounds(gameRoundId: $gameRoundId) {
        edges {
          node {
            brand {
              id
              name
            }
            currency
            gameId
            gameRoundId
            gameProvider
            startedAt
            completedAt
            totalBets
            totalWins
            totalJackpotContribution
            totalJackpotPayout
            openingBalance
            closingBalance
            status
            gameDescriptor {
              json
            }
          }
        }
      }
    }
  }
`;

export default function usePlayerGameRound(playerId: string) {
  const [query] = useQueryParams(queryParams);

  assert(query.gameRoundId, 'missing gameRoundId');

  const [{ data, fetching }, refresh] = useQuery<
    PlayerGameRoundInspectionV2,
    PlayerGameRoundInspectionV2Variables
  >({
    query: PLAYER_GAME_ROUNDS_QUERY,
    variables: {
      playerId,
      gameRoundId: query.gameRoundId,
    },
  });

  return {
    refresh,
    fetching,
    gameRound: data?.player.gameRounds?.edges?.map((edge) => edge?.node)[0],
  };
}
